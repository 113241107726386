import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonSolid from "../components/Button/ButtonSolid";
import TestimonialBackgroundImage from "../components/Testimonial/TestimonialBackgroundImage";
import About from "../components/Repeating/About";
import WhyUs from "../components/Repeating/WhyUs";
import Certifications from "../components/Repeating/Certifications";
import CTABackgroundColor from "../components/CTA/CTABackgroundColor";
import InstagramFeed from "../components/Repeating/InstagramFeed";

const Page = ({ data }) => {
   return (
      <Layout headerStyle="standard" headerLinkColor="dark" headerHasBorder={false}>
         <SearchEngineOptimization
            title="Private Dog Behavior Modification Training San Diego | Bella Woof"
            description="For top at-home dog training in San Diego, choose Bella Woof. Certified professional dog trainer April Bove-Rothwell offers custom training. Learn more!"
            openGraphImage={data.openGraphImage.publicURL}
            twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <div className="container">
            <section className="pt-2 md:pt-20 pb-20 md:pb-28">
               <div className="grid md:grid-cols-2 gap-y-8 md:gap-x-16 lg:gap-x-28 items-center">
                  <div className="order-2 md:order-1">
                     <h1>Private Dog Behavior Modification Training</h1>
                     <p>
                        Bella Woof owner and Certified Professional Dog Trainer April brings 25 years of training experience to your home. We understand that each dog’s personality and the bond you share are unique. For private behavioral modifications, we first get to know you and your dog better with an in-depth questionnaire. From there, April crafts a customized Behavior Modification Plan specifically for you and your pet. Behaviors addressed include fear/anxiety, aggression, reactivity, nuisance behaviors, leash walking, recall, obedience, puppy training and socialization, and basic household manners.
                     </p>
                     <ButtonSolid href="tel:+1-619-237-0730" text="(619) 237-0730" className="gtm-phone-number" />
                  </div>
                  <div className="order-1 md:order-2">
                     <Img fluid={data.intro.childImageSharp.fluid} alt="Professional Private Dog Training" />
                  </div>
               </div>
            </section>
         </div>

         <Certifications className="mb-20 md:mb-32" />

         <section className="bg-primary_lighter pt-22 pb-20 md:py-28">
            <div className="container">
               <div className="grid md:grid-cols-2 gap-y-8 md:gap-x-20 items-center mb-8 md:mb-6">
                  <div className="order-2 md:order-1">
                     <p className="text-primary text-xl font-extrabold mb-2">Beginner</p>
                     <h2>Puppy Training Packages</h2>
                     <p>
                        We offer customized behavior modification for both puppies and adult dogs. Our rewards-based behavior modification approach is a safe and effective
                        method for behavior modification right in the comfort of your home.
                     </p>
                  </div>
                  <div className="order-1 md:order-2">
                     <Img fluid={data.beginner.childImageSharp.fluid} alt="Puppy & Intro Adult Dog Training Package" className="rounded-3xl" />
                  </div>
               </div>

               <p className="text-mobile8xl md:text-8xl font-heading leading-none font-bold text-primary text-opacity-30 mb-10 md:mb-12">
                  Training Packages
               </p>
               <div className="grid md:grid-cols-2 lg:grid-cols-[1fr_440px_1fr] gap-y-8 md:gap-y-10">
                  <div className="md:border-l-2 md:border-solid md:border-primary md:pl-6">
                     <p className="font-heading text-gray-900 font-bold text-lg mb-2">Home Lessons:</p>
                     <ul>
                        <li>3 Lessons</li>
                        <li>1 hour each</li>
                     </ul>
                  </div>
                  <div className="md:border-l-2 md:border-solid md:border-primary md:pl-6">
                     <p className="font-heading text-gray-900 font-bold text-lg mb-2">Pricing:</p>
                     <ul>
                        <li>$350 Combo - Enrollment in Puppy class + one 1-hour long lesson</li>
                        <li>$450 - Puppies younger than 16 weeks at 1st lesson</li>
                        <li>$550 - Puppies 17 weeks to 8 months old at 1st lesson</li>
                     </ul>
                  </div>
                  <div className="md:border-l-2 md:border-solid md:border-primary md:pl-6">
                     <p className="font-heading text-gray-900 font-bold text-lg mb-2">Add-on Lessons:</p>
                     <ul>
                     <li>$125 per hour - younger than 16 weeks old</li>
                     <li>$150 per hour - 17 weeks to 8 months old</li>
                     </ul>
                  </div>
               </div>
            </div>
         </section>

         <section className="bg-secondary_light mb-20 md:mb-28 pt-22 pb-20 md:py-28">
            <div className="container">
               <div className="grid md:grid-cols-2 gap-y-8 md:gap-x-20 items-center mb-8 md:mb-6">
                  <div className="order-2 md:order-1">
                     <p className="text-secondary text-xl font-extrabold mb-2">Intermediate & Advanced</p>
                     <h2>Intermediate & Advanced Dog Training</h2>
                     <p>For dogs older than 17 weeks, Bella Woof offers private, customized behavioral modification for fear, anxiety, aggression, reactivity, obedience, nuisance behaviors, leash walking, recall, puppy obedience & socialization and basic household manners, all in the comfort of your own home!</p>
                  </div>
                  <div className="order-1 md:order-2">
                     <Img fluid={data.intermediateAdvanced.childImageSharp.fluid} alt="Advanced Dog Training" className="rounded-3xl" />
                  </div>
               </div>

               <p className="text-mobile8xl md:text-8xl leading-none font-heading font-bold text-secondary text-opacity-30 mb-10 md:mb-12">
                  Training Packages
               </p>
               <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-y-8 md:gap-y-10">
                  <div className="md:border-l-2 md:border-solid md:border-secondary md:pl-6">
                     <p className="font-heading text-gray-900 font-bold text-lg mb-2">Home Lessons:</p>
                     <ul>
                        <li>5 Lessons 1 hour each</li>
                        <li>8 Lessons 1 hour each</li>
                     </ul>
                  </div>
                  <div className="md:border-l-2 md:border-solid md:border-secondary md:pl-6">
                     <p className="font-heading text-gray-900 font-bold text-lg mb-2">Pricing:</p>
                     <ul>
                        <li>$795 older than 17 weeks</li>
                        <li>$1,195 older than 17 weeks</li>
                     </ul>
                  </div>
                  <div className="md:border-l-2 md:border-solid md:border-secondary md:pl-6">
                     <p className="font-heading text-gray-900 font-bold text-lg mb-2">Add-on Lessons:</p>
                     <ul>
                        <li>$150 per hour - 5-class package</li>
                        <li>$140 per hour - 8-class package</li>
                     </ul>
                  </div>
               </div>
            </div>
         </section>

         <WhyUs className="mb-20 md:mb-32" />

         <About className="mb-20 md:mb-32" />

         <div className="container">
            <section className="bg-secondary_light border border-solid border-secondary rounded-lg px-6 pt-10 md:pt-20 pb-14 md:pb-20 mb-20 md:mb-32">
               <div className="max-w-3xl mx-auto">
                  <img className={`mb-6 mx-auto`} src={data.info.publicURL} alt="Info" />
                  <header className="text-center">
                     <h4 className="text-lg mb-7">Training Cancellation/No-Show Policy </h4>
                     <p className="mb-0">
                        Oh no! Don’t worry, there’s no cancellation fee if you notify us in 48 hours or more before your appointment and we reschedule
                        it to a new day. For same-day cancellation, less than 48 hours notice, or “no-show,” at our discretion a $100 fee may apply to
                        book a new training appointment.
                     </p>
                  </header>
               </div>
            </section>
         </div>

         <TestimonialBackgroundImage />

         <CTABackgroundColor>
            <h2>Train with us!</h2>
            <p>
               Whether you have a puppy or an adult furry friend, we have classes in different locations to suit their needs. Get in touch to book
               training today!
            </p>
         </CTABackgroundColor>

         <InstagramFeed />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/At-home Training_FB.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/At-home Training_Twitter.jpg" }) {
         publicURL
      }
      intro: file(relativePath: { eq: "training/at-home/1.0-Intro_Desktop.png" }) {
         childImageSharp {
            fluid(maxWidth: 1256, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      beginner: file(relativePath: { eq: "training/at-home/Puppy-Intro-Adult-Dog-Training-Package.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 1096, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      intermediateAdvanced: file(relativePath: { eq: "training/at-home/Advanced-Dog-Training.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 1096, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      info: file(relativePath: { eq: "global/info.svg" }) {
         publicURL
      }
   }
`;

export default Page;
